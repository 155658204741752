import React, { useState } from 'react';
import styled from 'styled-components';
import carrer from '../img/images/career.jpg';

import { HeroSection, UnderLine, UnderlineHeader, Button } from './global-style';
import { HTMLContent } from './Content';
import MarkdownContent from './Markdown';

const Carriere = ({ jobs, title }) => {
  return (
    <div>
      <HeroSection bgImage={carrer} height="60vh">
        <ContactContainer>
          <h1>Carrière</h1>
          <p>
            Vous êtes passioné par les métiers de la communication et le marketing ? Créatif , curieux et bosseur ?
            Rejoignez notre agence pour découvrir une approche unique et intégrée au sein d'une ambiance cool et
            familiale !
          </p>

          <UnderLine />
        </ContactContainer>
      </HeroSection>
      <ContactUsSection>
        <UnderlineHeader>Vous voulez rejoindre notre équipe ?</UnderlineHeader>
        {jobs.map(job => {
          return (
            <>
              <h2>{job.jobTitle}</h2>
              <UnderLine />
              <section style={{margin:'20 0'}}>
              <MarkdownContent content={job.jobDescription} />
              </section>
              <Button>
                <a href={`mailto:carriere@tbwadjaz.com?subject=${job.jobTitle}`}>Je postule</a>{' '}
              </Button>
            </>
          );
        })}
      </ContactUsSection>
    </div>
  );
};

export default Carriere;

const ContactContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;
const ContactUsSection = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;

  .contact-info {
    display: flex;
    .manager {
      width: 500px;
    }
    h6 {
      margin: 5px 0;
    }
    img {
      width: 100%;
    }
    p {
      padding: 8px;
    }
  }
  @media (max-width: 800px) {
    .contact-info {
      flex-wrap: wrap;
      img {
        width: 100px;
      }
    }
  }
`;

const FormContainer = styled.div`
  padding: 20px 0;
  form {
    display: flex;
    flex-direction: column;
    textarea:focus,
    input:focus {
      outline: none;
    }
    input,
    textarea {
      border: unset;
      transition: ease-in-out, width 0.35s ease-in-out;
      padding: 20px 10px;
      background: var(--tbwa-gray);
      margin: 10px 0;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Carriere from '../components/carriere';
import Helmet from 'react-helmet';
import carrer from '../img/images/career.jpg';

export const CarrierePageTemplate = ({ jobs, title }) => {
  return <Carriere jobs={jobs} title={title} />;
};

CarrierePageTemplate.propTypes = {
  jobs: PropTypes.array,
};

const CarrierePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <>
      <Helmet>
        <html lang="fr" />
        <title>Carrière-TBWA \ DJAZ</title>
        <meta
          name="description"
          content="Vous êtes passioné par les métiers de la communication et le marketing ? Créatif , curieux et bosseur ? Rejoignez-nous !"
        />
             <meta
              property="og:image"
              content={`https://www.tbwadjaz.com${carrer}`}
              data-react-helmet="true"
            />
        <meta property="og:title" content="Carrière TBWA\ DJAZ" data-react-helmet="true" />
        <meta property="og:description" content={frontmatter.description} data-react-helmet="true" />
      </Helmet>
      <Layout>
        <CarrierePageTemplate title={frontmatter.title} jobs={frontmatter.jobs} />
      </Layout>
    </>
  );
};

CarrierePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export const carreireQuery = graphql`
  query CarrireQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        jobs {
          jobTitle
          jobDescription
        }
      }
    }
  }
`;

export default CarrierePage;
